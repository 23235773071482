import React, {Component} from 'react';
import Link from '@semcore/ui/link';
import Select from '@semcore/ui/select';
import {
  ButtonTrigger,
  IBaseTriggerProps,
  LinkTrigger,
} from '@semcore/ui/base-trigger';

interface IComponent {
  value: Array<string> | undefined;
  data: Array<{value: string}>;
  onChange: any;
  size?: 'm' | 'l' | 'xl';
  m?: string | number | undefined;
  tag?: any;
}

class SelectDomain extends Component<IComponent> {
  renderOptions = (item: any, index: number) => {
    return (
      <Select.Option.Checkbox
        key={item.value + '_domain_' + index}
        value={item.value}
      >
        {item?.title}
      </Select.Option.Checkbox>
    );
  };

  _chooseDomain = (value: any) => {
    const {data} = this.props;
    let chooseDomain = this.props.value;
    console.log('value', value);
    if (value == 'everyThing' || value[value.length - 1] == 'everyThing') {
      chooseDomain = data.map((item) => item.value);
    } else if (value == 'del' || value[value.length - 1] == 'del') {
      chooseDomain = [];
    } else if (chooseDomain == undefined) {
      chooseDomain = [value];
    } else {
      chooseDomain = value;
    }
    this.props.onChange(chooseDomain);
  };

  render() {
    const {value, data, size, m, tag} = this.props;
    return (
      <Select
        m={m}
        fontSize={size}
        onChange={this._chooseDomain}
        value={value}
        placeholder={'Select retailers'}
        multiselect
      >
        <Select.Trigger w={'200px'} tag={tag ? tag : ButtonTrigger}>
          {(tag ? '' : 'Retailers: ') +
            (value != undefined ? `${value?.length} selected` : 'All')}
        </Select.Trigger>
        <Select.Popper>
          {data.length > 0 && (
            <Select.List>
              {value?.length == data.length ? (
                <Select.Option value={'del'}>
                  <Link>
                    <Link.Text>Deselect all retailers</Link.Text>
                  </Link>
                </Select.Option>
              ) : (
                <Select.Option value={'everyThing'}>
                  <Link>
                    <Link.Text>Select all retailers</Link.Text>
                  </Link>
                </Select.Option>
              )}
              {data.map(this.renderOptions)}
            </Select.List>
          )}
        </Select.Popper>
      </Select>
    );
  }
}

export default SelectDomain;
