import {API_KEY} from './config';
import url, {youtubeURL} from './url';

class Api {
  token = undefined;

  tokenRefreshing = false; //флаг обновления токена

  get = ({...params}) => this.request({...params, method: 'GET'});
  post = ({...params}) => this.request({...params, method: 'POST'});
  put = ({...params}) => this.request({...params, method: 'PUT'});
  delete = ({...params}) => this.request({...params, method: 'DELETE'});

  getToken = () => {
    return new Promise((resolve, reject) => {
      window?.SM?.client('getAccessToken')
        .then((json) => {
          console.log('client get token', json);
          this.token = json;
          resolve();
        })
        .catch((error) => {
          reject();
          console.log('client get token error', error);
        });
    });
  };

  request = async ({route = '', method, headers = null, body}) => {
    let status = 200;
    let params = {
      method,
      headers: {
        Accept: 'application/json',
        Authorization: route.includes('youtube!')
          ? API_KEY
          : `Bearer ${this.token}`,
        ...headers,
      },
    };

    if (method != 'GET') params.body = JSON.stringify(body);
    console.log('paramsBeforeSend ====>', params);

    const URL = route.includes('youtube!') ? youtubeURL : url;

    const routeToPass = route.includes('youtube!')
      ? route.split('!')?.[1]
      : route;

    return new Promise((resolve, reject) => {
      // fetch(`${url}${route}${route.includes('?') ? '&' : '?'}jwt=${this.token}`, params)
      fetch(`${URL}${routeToPass}`, params)
        .then((response) => {
          status = response.status;
          console.log('status', status);
          switch (
            status //TODO: обработка неверного токена при авторизации, придумать как сделать элегантней
          ) {
            case 401:
              if (routeToPass === 'examples') {
                throw new Error('authTokenError');
                return;
              }
              if (this.tokenRefreshing === false) {
                this.tokenRefreshing = true; //запретить все запросы на какое-то время
                this.getToken()
                  .then(() => {
                    this.request({route, method, headers, body})
                      .then((json) => {
                        this.tokenRefreshing = false;
                        resolve(json);
                      })
                      .catch((error) => {
                        reject(error);
                      });
                  })
                  .catch(() => {});
              } else {
                let requestsInterval = setInterval(() => {
                  console.log('refresh work');
                  //ждем обновления токена
                  if (this.tokenRefreshing === false) {
                    //токен обновился
                    this.request({route, method, headers, body})
                      .then((json) => {
                        resolve(json);
                      })
                      .catch((error) => {
                        reject(error);
                      });
                    clearInterval(requestsInterval);
                  }
                }, 500);
              }
              throw new Error('tokenError');
            case 404:
              throw new Error('Ошибка в запросе, статус ' + status);
            default:
              return response.text();
          }
        })
        .then((text) => {
          let json = undefined;
          if (text) {
            json = JSON.parse(text);
          }

          switch (status) {
            case 200:
              resolve(json);
              break;
            case 500:
              if (json && json.Message) {
                reject(json);
              } else {
                throw new Error('Ошибка в запросе, статус ' + status);
              }
              break;
            default:
              throw new Error('Ошибка в запросе, статус ' + status);
          }
        })
        .catch((error) => {
          if (error.name == 'Error' && error.message === 'tokenError') {
          } else {
            reject(error);
          }
        });
    });
  };

  checkResponse = async (response) => {
    console.log(response.status);
    const {status} = response;
    switch (status) {
      case 400:
        throw response.json();
      case 422:
        throw response.json();
      case 200:
      case 201:
      case 202:
      case 203:
      case 422:
        return response.json();
      case 204:
        return;
      case 401:
      case 403:
        throw {status, message: 'Неавторизован'};
      case 404:
        throw {status, message: 'Не удалось найти запрос'};
      default:
        throw {
          status,
          message: 'Произошла ошибка, пожалуйста попробуйте повторить позднее',
        };
    }
  };
}

export default Api;
