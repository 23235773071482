import moment from 'moment';
import {AvailableCountriesList} from '../types/enums';
import {setQuery} from '../utils/helpers';
import {API_KEY} from './config';
import Api from './network';

export const api = new Api();

export enum Country {
  US = 'US',
  DE = 'DE',
}

export type GetDataParamsType = {
  keyword: string;
  date: string; //YYYY-MM
  country?: Array<String>;
  device?: string;
  shop?: Array<string>;
  [key: string]: any;
};

export type GetPlotParamsType = {
  keyword: string;
  date: string; //YYYY-MM
  monthbefore: number;
  country?: string;
  device?: string;
  domains?: string[];
};

export type GetAnalitycsByDomainParamsType = {
  keyword: string;
  date: string; //YYYY-MM
  country?: string;
  device?: string;
  domains?: string[];
};

export type GetRelatedKeywordsParamsType = {
  keyword: string;
  date: string; //YYYY-MM
  country?: string;
  device?: string;
  shops?: Array<string>;
  domains?: string[];
};

export type GetKeywordAnalyticsParamsType = {
  keyword: string;
  date: string; //YYYY-MM
  country?: string;
  device?: string;
  shops?: Array<string>;
};

export type GetAllTopKeywordsParamsType = {
  date: string; //YYYY-MM
  country?: string;
  device?: string;
  shops?: Array<string>;
};

export type KeywordListItem = {
  keyword: string;
  searches: number;
  rate: number;
  video_ref?: string[];
};
export type KeywordsList = KeywordListItem[];

export enum DEVICE_TYPE {
  mobile = 'mobile',
  desktop = 'desktop',
  all = 'all',
}
export type TopKeywordListItem = {
  keyword: string;
  searches: number;
  country: string;
  device_type: keyof DEVICE_TYPE;
  rate: number;
  date_agregation: string;
  video_ref?: string[];
};

export enum TimePeriod {
  day = 'day',
  week = 'week',
  month = 'month',
}

export type getTopKeywordsListRequest = {
  /** date from which aggregation will be running - YYYY-MM-DD */
  // startAggregationDate: string; - use period instead
  /**period of aggregation */
  period: TimePeriod;
  /** Country filter like USA, CAN. If the parameter is not provided in the request, the value is assumed = all. */
  country?: keyof typeof AvailableCountriesList;
  /** Device (desktop, mobile, all). If the parameter is not provided in the request, the value is assumed = all */
  device?: DEVICE_TYPE;
  /**name of the field and its sorting by asc or desc */
  order_by?: string;
  // sort_order: 'asc' | 'desc';
  /** rows per page */
  per_page: number;
  /** current page for pagination */
  page: number;
};
export type GetTopKeywordsListResponse = {
  keyword_data: TopKeywordListItem[];
  meta: {
    page: number;
    per_page: number;
    page_range: [number];
    total: number;
  };
};

export type GetSearchKeywordList = {
  searchKeyword: string;
  period: TimePeriod;
  order_by?: string;
  // a subject to change in the future, static for now
  country: keyof typeof AvailableCountriesList;
  per_page: number;
  page: number;
};

export const getExamples = async (country?: string) => {
  let route = `examples?month=${
    +moment().format('D') < 5
      ? moment().subtract(2, 'month').format('YYYY-MM')
      : moment().subtract(1, 'month').format('YYYY-MM')
  }`;
  if (country) route += `&country=${country}`;
  return await api.get({route});
};

export type TopVideosByKeywordResponse = {
  kind: string;
  etag: string;
  nextPageToken: string;
  prevPageToken: string;
  regionCode: string;
  pageInfo: {
    totalResults: number;
    resultsPerPage: number;
  };
  items: VideoTypeItem[];
};

export type VideoTypeItem = {
  kind: string;
  etag: string;
  id: {
    kind: string;
    videoId: string;
    channelId: string;
    playlistId: string;
  };
  snippet: {
    publishedAt: string;
    channelId: string;
    title: string;
    description: string;
    thumbnails: {
      [key: string]: {
        url: string;
        width: number;
        height: number;
      };
    };
    channelTitle: string;
    liveBroadcastContent: string;
  };
};

export type YoutubeVideosInfoResponse = {
  kind: string;
  etag: string;
  items: YoutubeVideoInfoItem[];
  pageInfo: {
    totalResults: number;
    resultsPerPage: number;
  };
};

export type VideoThumbnailType = {
  url: string;
  width: number;
  height: number;
};

export type YoutubeVideoInfoItem = {
  kind: string;
  etag: string;
  id: string;
  statistics: {
    viewCount: number;
    likeCount: number;
    favoriteCount: number;
    commentCount: number;
  };
  snippet: {
    publishedAt: string;
    channelId: string;
    title: string;
    description: string;
    thumbnails: {
      default: VideoThumbnailType;
      medium: VideoThumbnailType;
      high: VideoThumbnailType;
      standard: VideoThumbnailType;
      maxres: VideoThumbnailType;
    };

    channelTitle: string;
    tags: string[];
    categoryId: string;
    liveBroadcastContent: string;
    localized: {
      title: string;
      description: string;
    };
    defaultAudioLanguage: string;
  };
};

export type YoutubeChannelItem = {
  kind: string;
  etag: string;
  id: string;
  statistics: {
    viewCount: number;
    subscriberCount: number; // this value is rounded to three significant figures
    hiddenSubscriberCount: boolean;
    videoCount: number;
  };
  snippet: {
    title: string;
    description: string;
    customUrl: string;
    publishedAt: string;
    thumbnails: {
      default: VideoThumbnailType;
      medium: VideoThumbnailType;
      high: VideoThumbnailType;
      standard: VideoThumbnailType;
      maxres: VideoThumbnailType;
    };
  };
};

export type YoutubeChannelInfoResponse = {
  kind: string;
  etag: string;
  nextPageToken: string;
  prevPageToken: string;
  pageInfo: {
    totalResults: number;
    resultsPerPage: number;
  };
  items: YoutubeChannelItem[];
};

export type GetTopVideosResponse = {
  keyword_data: TopVideoType[];
};

export type TopVideoType = {
  link: string;
  video_id: string;
  picture: string;
  counts: number;
  result: string[];
  title: string;
  channel_name: string;
  num_subscribers: number;
  num_views: number;
  channel_id: string;
  /**YYYY-MM-DD */
  upload_date: string;
};

export type GetFastGrowingKeywordsParams = {
  // searchKeyword: string;
  period: TimePeriod;
  // a subject to change in the future, static for now
  country: string;
  per_page: number;
  page: number;
  order_by: string;
};

export type FastGrowingKeywordListItem = {
  counts: number;
  diff_array: number[];
  keyword_ref: string[];
  keyword_tar: string;
  last_diff: number;
  period: string;
  rate: number;
  video_ref?: string[];
};

export type GetFastGrowingKeywordsResponse = {
  // keyword_tar: string;
  // keyword_ref: string[];
  // counts: number;
  // last_diff: number;
  // diff_array: string[];
  // period: string;
  keyword_data: FastGrowingKeywordListItem[];
  meta: {
    page: number;
    page_range: number[];
    per_page: number;
    total: number;
  };
};

export type GetSearchFastGrowingKeywordsResponse = FastGrowingKeywordListItem[];

export const getExampleVideoKeywords = async () => {
  return await api.get({route: 'examples'});
};
/**
 * Returns sorted keywords most equal to query string
 * @param searchKeyword - keyword query to search
 * @param startAggregationDate - YYYY-MM-DD - date of beginning of aggregation
 * @returns
 */
export const getKeywordsList = async (
  // searchKeyword: string,
  // startAggregationDate: string,
  // country: string = 'all'
  params: GetSearchKeywordList
) => {
  return (await api.get({
    route: `search/${params.searchKeyword}/${params.period}?country=${params.country}&page=${params.page}&per_page=${params.per_page}&order_by=${params.order_by}`,
  })) as KeywordsList;
};
/**
 * Returns top keywords by provided date
 * @param params - query object
 * @returns
 */
export const getTopKeywordsList = async (params: getTopKeywordsListRequest) => {
  return (await api.get({
    route: `topkeywords/${params.period}?country=${params.country}&page=${params.page}&per_page=${params.per_page}&order_by=${params.order_by}`,
  })) as GetTopKeywordsListResponse;
};
/**
 * top 30 video ids by the given keyword from youtube
 *
 * WARNING
 * this method costs 100 traffic points, so it's not efficient to call it
 * @param keyword {string} search strings
 */
export const getTopVideosIdByKeyword = async (keyword: string) => {
  const videoParams = {
    key: API_KEY,
    type: 'video',
    maxResults: 30,
    order: 'viewCount',
    q: keyword,
  };
  const query = setQuery(videoParams);
  return (await api.get({
    route: `youtube!search${query}`,
  })) as TopVideosByKeywordResponse;
};

/**
 * video objects of provided video ids
 *
 * costs 1 traffic point per page
 * @param ids {String[]} - array of youtube video ids (max 30)
 * @returns detailed video info
 */
export const getYoutubeVideoInfo = async (ids: string[]) => {
  const videoParams = {
    key: API_KEY,
    part: 'snippet,contentDetails,statistics,player,recordingDetails,status',
    id: ids.join(','),
  };
  const query = setQuery(videoParams);
  return (await api.get({
    route: `youtube!videos${query}`,
  })) as YoutubeVideosInfoResponse;
};
/**
 * channel objects for provided channel ids array
 * @param channelIds {String[]} - array of youtube channel ids (max 30)
 * @returns detailed channel info
 */
export const getChannelInfo = async (channelIds: string[]) => {
  const channelParams = {
    key: API_KEY,
    part: 'statistics,id,snippet',
    id: channelIds.join(','),
    perPage: 30,
    maxResults: 30,
  };
  const query = setQuery(channelParams);
  return (await api.get({
    route: `youtube!channels${query}`,
  })) as YoutubeChannelInfoResponse;
};

export const getTopVideos = async (period: TimePeriod, country: string) => {
  return (await api.get({
    route: `topvideos/${period}?country=${country}&order_by=nameASC&device=all`,
  })) as GetTopVideosResponse;
};

export const getFastGrowingKeywords = async (
  params: GetFastGrowingKeywordsParams
) => {
  return (await api.get({
    route: `fast_grow_keyword/${params.period}?page=${params.page}&per_page=${params.per_page}&country=${params.country}&order_by=${params.order_by}`,
  })) as GetFastGrowingKeywordsResponse;
};

export type GetSearchFastGrowingKeywordListParams = {
  keyword: string;
  period: string;
  country: string;
  device: string;
  order_by: string;
};

export const getFastGrowingKeywordsList = async (
  params: GetSearchFastGrowingKeywordListParams
) => {
  console.log('_-searchParams', params);
  return (await api.get({
    route: `fast_grow_kw/search/${params.keyword}/${params.period}?country=${params.country}&device=${params.device}&order_by=${params.order_by}`,
  })) as GetSearchFastGrowingKeywordsResponse;
};
