import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {Text} from '@semcore/ui/typography';
import Notice from '@semcore/ui/notice';
import style from './style.module.scss';

export interface GlobalNoticeRef {
  open: () => void;
}

interface Props {
  text: string;
  theme?: string;
}

export const GlobalNotice = forwardRef<GlobalNoticeRef, Props>(
  ({text, theme}, ref) => {
    const [opened, setOpened] = useState(false);

    useImperativeHandle(ref, () => ({
      open: () => open(),
      close: () => close(),
    }));

    const open = () => {
      setOpened(true);
    };

    const close = () => {
      setOpened(false);
    };

    const handleClick = () => {
      close();
    };

    return (
      <Notice
        hidden={!opened}
        theme={theme ? theme : 'info'}
        use="primary"
        className={style.global_notice}
      >
        <Notice.Content className={style.global_notice_content}>
          <Text tag="span" className={style.global_notice_text}>
            {text}
          </Text>
        </Notice.Content>
        <Notice.CloseIcon interactive={false} onClick={() => handleClick()} />
      </Notice>
    );
  }
);
